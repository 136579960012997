<template>
  <div class="text-h6">
    {{ $t('Welcome back') }}
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>